define("discourse/plugins/discourse-translator/discourse/components/translated-post", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    loading(translated_text) {
      return translated_text === true ? true : false;
    }
  }, [["method", "loading", [(0, _decorators.default)("post.translated_text")]]]));
});